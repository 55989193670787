.spMenu {
  width: 40rem;
  max-height: 100svh;
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  overflow: auto;

  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  &.isOpen {
    opacity: 1;
    visibility: visible;
  }
  & > div {
    background-color: white;
    padding: 4.8rem 1.6rem 0;
  }
}

.spMenuButtonInner {
  padding-bottom: 2rem;
}

.spMenuTel {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  color: var(--blue9);
  border-bottom: 1px solid #18364f;
  border-top: 1px solid #18364f;
  span:nth-of-type(1) {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 0.8rem;
  }
  span:nth-of-type(2) {
    font-size: 3.6rem;
    font-weight: bold;
  }
}

.spMenuContact {
  height: 7.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18364f;
  span:nth-of-type(1) {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0 0.7rem;
    color: #18364f;
    background-color: white;
    margin-right: 0.8rem;
    border-radius: 1.1rem;
  }
  span:nth-of-type(2) {
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }
}

.spMenuDocument {
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  box-shadow: var(--shadow5);
  text-decoration: none;
  padding: 8px 8px;
  transition: 0.5s;
  width: 36rem;
  border-radius: 4px;
  position: relative;
  color: #fff;
  background-color: #1446de;
  border: 1px solid #1446de;

  &:hover {
    color: #1446de;
    background-color: #fff;
  }

  &::after {
    content: "→";
    margin-left: 8px;
  }
}

.spMenuList {
  margin: 4.5rem -1.6rem 0;
}

.spMenuListItemTop {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.spMenuListItem {
  border-bottom: 1px solid #e6e6e6;
}

.spMenuListButton {
  width: 100%;
  padding-left: 1.8rem;
  padding-right: 2.4rem;
  background-color: white;
  color: #001a88;
  font-size: 2rem;
  line-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.spMenuCategoryList {
  padding: 4rem 2rem;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "areaA areaB"
    "areaC areaD";
  & > li:nth-of-type(1) {
    grid-area: areaA;
    margin-bottom: 2.4rem;
  }
  & > li:nth-of-type(2) {
    grid-area: areaB;
    margin-bottom: 2.4rem;
  }
  & > li:nth-of-type(3) {
    grid-area: areaC;
  }
  & > li:nth-of-type(4) {
    grid-area: areaD;
  }
}

.details[open] {
  .summaryArrow {
    transform: rotate(90deg);
  }
}

.categoryHeader {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  &::before {
    content: "";
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    border-radius: 50%;
    background-color: #333;
  }
}

.secondListItem {
  width: 18rem;
  margin-bottom: 0.8rem;
}

.inner {
  max-width: 150rem;
  margin: 0 auto;
  padding: 0rem 2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    padding: 0.8rem 2rem;
  }
}
.top {
  position: sticky;
  top: 0;
  z-index: 2000;
  &::after {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    background-color: hsla(0, 0%, 100%, 0.3);
  }
}
.logo {
  margin-right: 12rem;
  width: 17rem;
}
.box {
  width: 40rem;
  display: flex;
  align-items: center;
}
.navList {
  display: flex;
  li:not(:last-of-type) {
    margin-right: 4.8rem;
  }
}

.navListLi {
  font-size: 1.5rem;
  padding-top: 18px;
  padding-bottom: 18px;
}

.tel {
  display: flex;
  color: var(--blue2);
  margin-left: 1rem;
}

.telIcon {
  width: 2.3rem;
  height: 3rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue2);
}

.telText1 {
  margin-bottom: 0.4rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}
.telText2 {
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 1;
}

.contact {
  padding: 0 1.6rem;
  margin-left: 1.2rem;
  line-height: 5rem;
  font-size: 1.6rem;
  text-align: center;
  background-color: var(--blue2);
  color: white;
  line-height: 3.8rem;
  border-radius: 0.8rem;
}

.companyLink {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--blue3);
  position: absolute;
  right: 4rem;
  top: 2rem;
  &::before {
    content: "";
    margin-right: 1rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 11px;
    border-color: transparent transparent transparent #4a8fda;
  }
}

.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  box-shadow: var(--shadow4);
  border-top: 1px solid #eee;
}

.bottomList {
  display: flex;
}

.bottomListItem {
  &:not(:last-of-type) {
    margin-right: 3rem;
  }
}

.searchForm {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  input {
    width: 23.7rem;
    padding-left: 4rem;
    padding-right: 1.2rem;

    border: 1px solid #eee;
    border-radius: none;
    font-size: 1.4rem;
    display: none;

    &:focus {
      outline: 1px solid var(--blue2);
    }
  }
  button[type="submit"] {
    height: 3.5rem;
    padding: 0.4rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue2);
    color: white;
    display: none;
  }
}

.openButton {
  position: relative;
  z-index: 2;
}

.closeButton {
  width: 3rem;
  height: 3rem;
  margin-left: 0.8rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    width: 1.5rem;
    height: 0.1rem;
    background-color: var(--grey6);
    transform: rotate(-45deg);
  }
  &::after {
    content: "";
    width: 1.5rem;
    height: 0.1rem;
    margin-top: -0.1rem;
    background-color: var(--grey6);
    transform: rotate(45deg);
  }
}

.box.isOpen {
  .tel {
    display: none;
  }
  .contact {
    display: none;
  }
  .searchForm {
    opacity: 1;
    visibility: visible;
    margin-left: -3.2rem;

    input {
      display: block;
    }
    button[type="submit"] {
      display: block;
    }
  }
  .closeButton {
    display: flex;
  }
}

.telBtn {
  display: none;
}

.menuBtn {
  display: none;
}

.bottomListButton {
  color: var(--grey6);
  position: relative;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0.3rem;
    transition: width 0.3s;
    background-color: var(--blue2);
    z-index: -1;
  }
  &:hover {
    opacity: 1;
  }
}
.bottomListButton:hover {
  color: var(--blue2);
  &::after {
    width: 100%;
  }
}
.bottomListButton.isActive {
  color: var(--blue2);
  &::after {
    width: 100%;
  }
}

.hoverSpace {
  position: absolute;
  top: 100%;
  width: 90%;
  max-height: calc(100vh - 13rem);
  overflow: auto;
  max-width: 100rem;

  left: 50%;
  display: flex;
  flex-direction: column;

  transform: translateX(-50%);
  z-index: 1000;
  background-color: white;
  box-shadow: var(--shadow-primary);
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  &:hover {
    visibility: visible;
    opacity: 1;
  }
}
.hoverSpaceInner {
  display: flex;
  flex-grow: 1;
}
.hoverNavList {
  width: 28rem;
  flex-shrink: 0;
  padding: 3rem 3rem 3rem 5rem;
  border-right: 1px solid #ced0d4;
}

.hoverNavListItem {
  margin-bottom: 1rem;
}

.hoverNavListButton {
  padding-bottom: 1rem;
  color: var(--grey10);
  &.isActive {
    color: var(--blue2);
  }
  &:hover {
    opacity: 1;
  }
}

.hoverPostList {
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &::after {
    content: "";
    max-width: 20rem;
    width: 31%;
  }
}

.hoverPostItem {
  max-width: 20rem;
  width: 31%;
  margin-bottom: 2rem;
  margin-right: 0px !important;
}

.hoverPostImage {
  margin-bottom: 1.8rem;
  overflow: hidden;
  border-radius: 1rem;
}

.hoverPostCateories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
}

.hoverPostTop {
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
}

@media screen and (max-width: 1300px) {
  .logo {
    margin-right: 12rem;
  }
  .navList {
    li:not(:last-of-type) {
      margin-right: 3rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .spMenu {
    display: block;
  }

  .spMenu.isOpen + .top {
    .box {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    .searchForm {
      opacity: 1;
      visibility: visible;
      margin-left: -3.2rem;

      input {
        display: block;
      }
      button[type="submit"] {
        display: block;
      }
    }
  }

  .nav {
    display: none;
  }
  .box {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    width: auto;
    position: fixed;
    right: 6rem;
    top: 7.8rem;
    .closeButton {
      display: none !important;
    }
    // .searchForm {
    //   display: flex !important;
    //   opacity: 1 !important;
    //   visibility: visible !important;
    // }
  }
  .companyLink {
    display: none;
  }
  .bottom {
    position: static;
  }
  .bottomList {
    display: none;
  }

  .tel {
    display: none;
  }
  .contact {
    display: none;
  }

  .closeButton {
    display: flex;
  }
  .searchForm {
    top: 3rem;
    right: 10rem;
  }
  .telBtn {
    display: block;
    width: 2.1rem;
    height: 2.1rem;
    position: fixed;
    top: 2.2rem;
    right: 7rem;
    z-index: 3000;
  }
  .menuBtn {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: fixed;
    right: 2rem;
    top: 1.2rem;
    transition: background-color 0.3s;
    z-index: 3000;

    // &:hover {
    //   opacity: 1;
    //   background-color: var(--blue7);
    //   & > span > span {
    //     background-color: white;
    //   }
    // }

    & > span {
      width: 60%;
      height: 0.2rem;
      position: relative;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #333;
        transition: background-color 0.3s;
        &:nth-of-type(1) {
          transform: translateY(-0.7rem);
        }
        &:nth-of-type(3) {
          transform: translateY(0.7rem);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header.isOpen {
    .logo {
      visibility: hidden;
    }
    .searchForm {
      input {
        display: block;
      }
    }
    .closeButton {
      display: flex;
    }
  }
  .inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .top {
    padding-top: 0.5rem;
  }
  .logo {
    width: 15rem;
    height: 3.8rem;
    position: relative;
    img {
      position: absolute;
      width: 200%;
      max-width: none;
      top: -50%;
      left: -50%;
      transform: scale(0.5);
    }
  }

  .box {
    right: calc(50% - 14rem);
  }

  .telBtn {
    top: 2rem;
    right: 5.6rem;
  }

  .menuBtn {
    top: 1rem;
    right: 1rem;
  }

  .spMenu {
    width: 100%;
  }

  .spMenuTel {
    margin-bottom: 2rem;
    justify-content: center;
    span:nth-of-type(2) {
      font-size: 3rem;
    }
  }
  .spMenuContact {
    height: 6rem;
    span:nth-of-type(1) {
      font-size: 1.3rem;
    }
    span:nth-of-type(2) {
      font-size: 1.8rem;
    }
  }

  .spMenuDocument {
    height: 6rem;
    margin-top: 2rem;
    font-size: 1.8rem;
  }

  .spMenuList {
    margin-top: 2.5rem;
  }

  .spMenuListButton {
    font-size: 1.8rem;
    line-height: 4.5rem;
  }

  .details {
    .summaryArrow {
      width: 1.5rem;
    }
  }

  .spMenuCategoryList {
    font-size: 1.2rem;
  }
  .secondListItem {
    width: 16.5rem;
  }
}

.grayOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popupMenuContainer {
  position: absolute;
  top: 100%;
  width: 80%;
  max-height: calc(100vh - 13rem);
  overflow: auto;
  max-width: 100rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: 20px;

  transform: translateX(-50%);
  z-index: 1000;
  background-color: white;
  box-shadow: var(--shadow-primary);
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  &:hover {
    visibility: visible;
    opacity: 1;
  }
  p {
    font-size: 2rem;
    margin-left: 30px;
    font-weight: bold;
  }
}

.popupMenuInner {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.popupItem {
  display: flex;
  flex-wrap: wrap;
  span {
    font-size: 1.5rem;
    color: #565656;
  }
}

.popupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  margin-left: 15px;
  margin-right: 25px;
  padding: 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  border-width: 1px 0;
  background-color: #fff;
  cursor: pointer;
}

.buttonIcon {
  background-color: #1446de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  .moreSectionButton {
    width: 60vw;
  }
}
