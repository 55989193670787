.footer {
  padding-bottom: 3rem;
  position: relative;
}

.top {
  padding: 1rem 1.5rem;
  background: linear-gradient(to right, #9796f0, #fbc7d4);
  display: flex;
  justify-content: center;
  > a {
    &:nth-of-type(1) {
      margin-right: 2rem;
    }
  }
}
.middle {
  padding-top: 10.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.middleInner {
  max-width: 146rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.middleLeft {
  width: 25rem;
  flex-shrink: 0;
}

.snsLinksText {
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--grey2);
  font-size: 1rem;
  font-weight: bold;
}

.snsLinks {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-right: 1.8rem;
    }
  }
}

.middleRight {
  margin-left: 4rem;
  display: flex;
}

.listTitle {
  margin-bottom: 3rem;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: var(--sub-font);
}
.listSubTitle {
  margin-top: -1.8rem;
  margin-bottom: 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--grey6);
  white-space: nowrap;
}

.listItem {
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
}
.middleRightBlock {
  &:last-of-type {
    margin-left: 2.4rem;
    width: auto;
    flex-grow: 1;
  }
}

.middleRightBlockBox {
  display: flex;
}

.middleRightBlockItem1 {
  margin-right: 2.4rem;
}

.middleRightBlockItem2 {
  margin-right: 2.4rem;
  &:last-of-type {
    margin-right: 0;
  }
}

.list {
  margin-bottom: 3.4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.listItem {
  margin-right: 1.6rem;
  min-width: 12rem;
  font-size: 1.4rem;
  a {
    font-size: 1.4rem;
    white-space: nowrap;
    width: fit-content;
  }
}

.bottom {
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 129.1rem;
  margin: 5.8rem auto 10.8rem;
  &::after {
    content: "";
    display: block;
    width: 21.9%;
    height: 0.1rem;
    margin: 0.6rem auto 0;
    background-color: var(--grey4);
  }
}

.bottomList {
  margin-top: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bottomListItem {
  width: 18.86%;
  margin-bottom: 1.425%;
  position: relative;
}
.bottomListImageWrap {
  display: block;
  padding-top: 56.74%;
  position: relative;
  overflow: hidden;
}

.bottomListButton {
  width: 100%;

  img {
    object-fit: cover;
    transition: transform 0.3s;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey7);
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.bottomListItemName {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-family: var(--mincho-font);
  z-index: 10;
  filter: drop-shadow(var(--shadow-secondary));
}

.bottomListItemArrow {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 2px solid;
  color: white;
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 1.6rem);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    width: 1.2rem;
    height: 0.2rem;
    background-color: white;
  }
  &::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: -0.9rem;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    z-index: 0;
  }
}

.copyRight {
  max-width: 150rem;
  padding: 0 2rem;
  margin: 0 auto;
  font-size: 1.2rem;
  font-family: var(--sub-font);
  color: var(--black3);
}

@media screen and (max-width: 1540px) {
  .middleLeft {
    width: 24rem;
  }
  .middleRight {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 1440px) {
  .middleLeft {
    width: 27.8rem;
  }
  .middleRight {
    width: 78rem;
    margin-left: 3rem;
    flex-wrap: wrap;
  }

  .middleRightBlock {
    &:last-of-type {
      margin-top: 3rem;
      margin-left: 0;
      width: auto;
      flex-grow: 0;
    }
  }
}

@media screen and (max-width: 1240px) {
  .middleLeft {
    width: 21rem;
  }
  .bottomListItem {
    width: 23.86%;
    margin-bottom: 1.425%;
  }
}

@media screen and (max-width: 1120px) {
  .middle {
    padding-top: 6rem;
    padding-left: 0;
    padding-right: 0;
  }
  .middleInner {
    flex-direction: column;
  }
  .middleLeft {
    width: 27.8rem;
    margin: 0 auto 4rem;
  }
  .middleRight {
    width: 90vw;
    max-width: 60rem;
    margin: 0 auto;
  }
  .middleRightBlockBox {
    flex-wrap: wrap;
  }
  .middleRightBlockItem1 {
    margin-bottom: 1rem;
  }
  .bottom {
    width: 90vw;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  .bottomListItem {
    width: 49.3%;
    margin-bottom: 1.425%;
  }
}

@media screen and (max-width: 600px) {
  .top {
    flex-direction: column;
    align-items: center;
    > a {
      max-width: 32rem;
      &:nth-of-type(1) {
        margin-right: 0;
        margin-bottom: 0.4rem;
      }
      img {
        width: 100%;
      }
    }
  }
  .middle {
    padding-top: 3rem;
  }
  .middleLeft {
    width: 100%;
    position: absolute;
    bottom: 6rem;
  }
  .logo {
    width: 24.5rem;
    height: 6.2rem;
    margin: 0 auto;
    position: relative;
    img {
      position: absolute;
      width: 200%;
      max-width: none;
      top: -50%;
      left: -50%;
      transform: scale(0.5);
    }
  }
  .snsLinksText {
    width: 17rem;
    margin-top: 7.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
    border: none;
  }
  .snsLinks {
    margin-top: 0;
    align-items: center;
    a {
      position: relative;
      img {
        position: absolute;
        width: 200%;
        max-width: none;
        top: -50%;
        left: -50%;
        transform: scale(0.5);
      }
    }
    a:nth-of-type(1) {
      width: 2rem;
      height: 2.1rem;
    }
    a:nth-of-type(2) {
      width: 2.4rem;
      height: 2.5rem;
    }
    a:nth-of-type(3) {
      width: 4.9rem;
      height: 1.1rem;
    }
  }
  .middleRight {
    width: 100%;
    padding: 0 1.5rem;
  }
  .middleRightBlock {
    &:first-of-type {
      width: 15.3rem;
    }
    &:last-of-type {
      margin-top: 0;
      padding-top: 3rem;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
  .middleRightBlockItem1 {
    width: 15.3rem;
    margin-right: 0;
  }
  .middleRightBlockItem2 {
    width: 16rem;

    margin-right: 0;
  }
  .list {
    max-height: 30rem;
    max-width: 33.2rem;
    margin-bottom: 2rem;
  }
  .listTitle {
    font-weight: normal;
  }
  .listItem {
    width: 15rem;
    margin-right: 0;
    a {
      font-size: 1.2rem;
    }
  }
  .listSubTitle {
    margin-top: 0;
    color: black;
    font-weight: normal;
    font-size: 1.3rem;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: flex-start;
    &::before {
      content: "";
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      background-color: black;
      transform: translateY(0.5rem);
      border-radius: 50%;
      margin-right: 0.2rem;
    }
  }
  .bottom {
    width: 100%;
    padding: 0 1.5rem;
    margin: 2rem 0;
    &::after {
      display: none;
    }
  }
  .bottomList {
    margin-top: 5rem;
  }
  .bottomListItem {
    width: 100%;
    border: 1px solid var(--grey15);
    // margin-bottom: 1.425%;
  }
  .bottomListItemName {
    filter: none;
    color: black;
    font-family: var(--sub-font);
    font-size: 1.2rem;
    width: auto;
    left: 31%;
  }
  .bottomListButton {
    display: flex;
    padding-top: 0;
    &::after {
      display: none;
    }
  }
  .bottomListImageWrap {
    width: 26.8%;
    padding-top: 12.3%;
  }
  .bottomListItemArrow {
    border: none;
    color: #9796f0;
    &::after {
      width: 0.7rem;
      height: 0.7rem;
      border-width: 0.1rem;
      border-color: var(--black6);
    }
  }
  .copyRight {
    margin-top: 28rem;
    text-align: center;
    padding: 0 1.5rem;
  }
}
